import $ from "jquery";
import {Tab, Tooltip, Popover, Button, Dropdown, Util} from 'bootstrap/dist/js/bootstrap.js';
import * as popperjs from '@popperjs/core';
import * as dompack from 'dompack';
import * as magnificpopup from 'magnific-popup';
import slick from 'slick-carousel';
import * as sidebarjs from 'sidebarjs';

import 'lazysizes';

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) 
{
  return new Tooltip(tooltipTriggerEl)
})

$( document ).ready(function() {

    // sidemenu
    new sidebarjs.SidebarElement({
        component: document.querySelector('[sidebarjs="leftSidebar"]'),
        nativeSwipeOpen: false,
        nativeSwipe: false,
        onOpen: function() 
        {
          document.documentElement.classList.add("sidebarjs--open")
        },
        onClose: function() 
        {
          document.documentElement.classList.remove("sidebarjs--open")
        },
    });

    // filter sidemenu
    var filterpanel = document.querySelector('[sidebarjs="filterSideNav"]');

    if (typeof (filterpanel) != 'undefined' && filterpanel !== null)
    {

      var filterSidebarjs = new sidebarjs.SidebarElement({
          component: document.querySelector('[sidebarjs="filterSideNav"]'),
          nativeSwipeOpen: false,
          nativeSwipe: false,
          onOpen: function() 
          {
            document.documentElement.classList.add("sidebarjs--open")
          },
          onClose: function() 
          {
            document.documentElement.classList.remove("sidebarjs--open")
          },
      });

    }

    // toggle ul in sidemenu
    $(".has-submenu > .submenu-toggle").click(function()
    {
      $(this).next('ul').toggleClass('d-block');
      $(this).toggleClass('collapse--open');
      $(this).parent().parent().find('.submenu').hide();
      $(this).parent().parent().removeClass('collapse--open');
    });

    //
    var li_height = $(".widget-tagcloud" ).find("li").height();
    $(".widget-tagcloud" ).find( ".tagitems" ).height(li_height*10);

    var clicked=1;
    $('#show_more').click(function()
    {
    if(clicked)
    {
        var li_height = $(".widget-tagcloud" ).find("li").height();
        var li_count = $(".widget-tagcloud li" ).length+1;
        $(".widget-tagcloud" ).find( "ul" ).animate({'height': li_height*li_count + 'px'});
        $(".widget-tagcloud" ).css({'height' : li_height*li_count+50 + 'px' });
        clicked=0;
        $(this).text('Minder items');
    } else {
        var li_height = $(".widget-tagcloud" ).find( "li" ).height();
        $(".widget-tagcloud" ).find( "ul" ).animate({ 'height': (li_height * 10) + 'px' });
        $(".widget-tagcloud" ).animate({ 'height': li_height* 6-20 + 'px' });

        clicked=1;
        $(this).text('Meer items');
    }
    });

    // buttongroep slider widget
    $('.widget-innerlinks').slick({
      infinite: false,
      slidesToShow: 3,
      centerMode: false,
      variableWidth: true,
      speed: 300,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            arrows: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.5
          }
        }
      ]
    });

    // filter sidemenu
    var slickarrow = $('.widget-innerlinks.slick-initialized');
    if (typeof (slickarrow) != 'undefined' && slickarrow !== null)
    {
      slickarrow.each(function() 
      {
        if($(this).children('button.slick-arrow').length !== 0)
        {
          $(this).addClass('slick-arrows');
        }
      });
    }

    // merken slider widget
    $('.brand-slider').slick({
      infinite: true,
      slidesToShow: 5,
      speed: 300,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            arrows: false,
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1300,
          settings: {
            arrows: false,
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1200,
          settings: {
            arrows: false,
            slidesToShow: 5
          }
        },
          {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 2
          }
        }
      ]
    });

    // product slider widget
    $('.webshop--iscategorypage .products-slider, .page--rtd .products-slider').slick({
      slidesToShow: 3,
      infinite: true,
      speed: 300,
      vertical: false,
      //autoplay: true,
      //autoplaySpeed: 2000,
      responsive: [
          {
          breakpoint: 1200,
          settings: {
            arrows: true,
            slidesToShow: 3
          }
        },
          {
          breakpoint: 992,
          settings: {
            arrows: true,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    });

    // $(document).on('shown.bs.collapse', function(event){
    //     event.target.scrollIntoView();
    // });
});