import * as dompack from '@webhare/dompack';
import * as pricecalculation from '../internal/pricecalculation';
import * as finmath from '@mod-system/js/util/finmath';
import { getTid } from "@mod-tollium/js/gettid";
import { OnCalculatePriceCallback, ProductPriceInfo } from '../internal/types';
import Webshop from "./index";
import { navigateTo } from '@webhare/frontend';

const priceinfosymbol = Symbol("webshop-priceinfo");

type ExtendedPriceNode = HTMLElement & { [priceinfosymbol]?: ProductPriceInfo };
export type ProductTypeConstructor = new (node: HTMLElement, product: Product, initialhashparams: URLSearchParams) => ProductTypeHandler;

export class ProductTypeHandler {
  node;
  product;
  initialhashparams;

  constructor(node: HTMLElement, product: Product, initialhashparams: URLSearchParams) {
    this.node = node;
    this.product = product;
    this.initialhashparams = initialhashparams;

    //we need indirections to be able to invoke user-overriden interfaces TODO but we could also use them to validate the user's implementations!
    this.product.oncalculateprice = this._onCalculatePrice.bind(this);
  }

  calculatePrice(baseprice: string/*finmath.FinmathInput*/, hashparams: URLSearchParams, amount: number): string | "onrequest" {
    return baseprice;
  }

  _onCalculatePrice(baseprice: string/*finmath.FinmathInput*/, hashparams: URLSearchParams, amount: number): finmath.FinmathInput | "onrequest" {
    const result = this.calculatePrice(baseprice, hashparams, amount);
    if (result !== "onrequest" && !finmath.isValidPrice(result))
      throw new Error(`The oveerride for calculatePrice returned an invalid price '${result}'`);
    return result;
  }
}

export function getDynPriceInfo(productnode: HTMLElement): ProductPriceInfo {
  if (!productnode.dataset.webshopDynpriceinfo) {
    console.error(`Invalid product node, missing dataset.webshopDynpriceinfo: %o`, productnode);
    throw new Error(`Invalid product node, missing dataset.webshopDynpriceinfo`);
  }

  return (productnode as ExtendedPriceNode)[priceinfosymbol] ||= new ProductPriceInfo(productnode);
}

export class Product {
  readonly webshop: Webshop;
  readonly node: HTMLElement;
  oncalculateprice: OnCalculatePriceCallback | undefined = undefined;
  oncalculateconfiguration: (() => unknown) | null = null;
  oncheckbeforeadd: (() => boolean) | null = null;
  _initialized: boolean = false;
  _initializedpromise: Promise<void>;
  productinfo;
  dynpriceinfo;
  _currentoptions = [];
  _skippriceupdates = true; // no price updates (and hash rewrites) during init
  _updating_selects = false;
  onselectorchange: (() => void) | null = null;

  constructor(webshop: Webshop, node: HTMLElement) {
    this.webshop = webshop;
    this.node = node;

    if (!this.node.dataset.webshopProduct) {
      console.error("missing dataset.webshopProduct, did you add [product.formattributes]?", node);
      throw new Error("missing dataset.webshopProduct, did you add [product.formattributes]?");
    }

    this.productinfo = JSON.parse(this.node.dataset.webshopProduct);
    this.dynpriceinfo = JSON.parse(this.node.dataset.webshopDynpriceinfo!) as ProductPriceInfo;

    dompack.qSA(this.node, ".webshop-product__add").forEach(
      addbutton => addbutton.addEventListener("click", event => this._doAdd(event)));
    dompack.qSA(this.node, ".webshop-product__optionselect").forEach(
      optionselect => optionselect.addEventListener("change", event => this._onSelectorChange()));
    dompack.qSA(this.node, "[name=amount]").forEach(
      amountinput => amountinput.addEventListener("input", event => this._updatePrice()));

    window.addEventListener("webshop:cartupdated", event => this._cartUpdated());
    this._initializedpromise = this.init();
  }

  get id() {
    return this.productinfo.id;
  }

  getProductTitle() {
    return this.productinfo.title;
  }

  getProductValuesFromHash(hashparams: URLSearchParams) {
    const productvalues = [];
    for (const opt of this.productinfo.fixedoptions)
      productvalues.push(opt.productvalue);

    for (const select of dompack.qSA<HTMLSelectElement>(this.node, ".webshop-product__optionselect")) {
      const value = hashparams.get('po_' + select.dataset.productoption);
      if (!value)
        continue;

      const selected = Array.from(select.options).find(opt => opt.dataset.productvalue == value);
      if (selected?.value)
        productvalues.push(Number(selected.value));
    }

    return productvalues;
  }

  async init(): Promise<void> {
    const hashparams = new URLSearchParams((new URL(location.href)).hash.substring(1));

    const productvalues = this.getProductValuesFromHash(hashparams);
    /* We need to be sure init has completed executed before products are added so they
       have a chance to modify product handling. This has raced in the past, and they were more easily reproduced by
       waiting here:
       await new Promise(resolve => setTimeout(resolve,3000));
    */

    await this._updateOptions(
      { productvalues },
      {
        inithandlers_cb: () => {
          // Let the product handlers process the configuration
          this.webshop.options.productpagetypes.forEach(type => {
            if (type.name === (this.productinfo.type || 'defaultproduct'))
              new type.handler(this.node, this, hashparams);
          });
        }
      });

    this._initialized = true;
  }

  _updateAfterSelectionChange({ sethash = false, forcartupdate = false } = {}) {
    const { fixedoptions, options } = this._gatherCurrentOptions();
    this._updateOptions(
      { productvalues: [...fixedoptions, ...options] }, { sethash, forcartupdate });
  }

  private async _updateOptions(productoptions: { productvalues: number[] }, { inithandlers_cb, sethash, forcartupdate = false }: {
    inithandlers_cb?: () => void;
    sethash?: boolean;
    forcartupdate?: boolean;
  } = {}) {
    const lock = dompack.flagUIBusy();
    this._updating_selects = true;
    try {
      const res = await this.webshop._getProductEnabledOptions(this.productinfo.id, { ...productoptions, allowselectionoutofstock: forcartupdate });

      let gotchange = false;

      // Process the selection, enabled options and hidden options from the options
      for (const optionrow of dompack.qSA(this.node, ".webshop-product__option")) {
        const select = dompack.qR<HTMLSelectElement>(optionrow, "select.webshop-product__optionselect");
        const productoption = Number(select.dataset.productoptionid);

        const sel = res.selection.find(_ => _.productoption === productoption);
        for (const optionnode of select.options) {
          const productvalue = Number(optionnode.value) | 0;
          if (!productvalue)
            continue;

          const active = sel?.candidates?.includes(productvalue);

          if (optionnode.disabled != !active) {
            // set classes and disable inactive options
            optionnode.disabled = !active;
            optionnode.hidden = !active;
            optionnode.classList.toggle("webshop-product__optionselect--inactive", !active);
            gotchange = true;
          }
        }

        optionrow.classList.toggle("webshop-product__option--hiddendefault", sel?.hiddendefault ?? false);

        if (select.value != (sel?.productvalue || "")) {
          dompack.changeValue(select, sel?.productvalue || "");
          gotchange = true;
        }

        if (gotchange)
          dompack.dispatchCustomEvent(select, "webshop:optionschanged", { bubbles: true, cancelable: false });
      }

      if (gotchange && this.onselectorchange)
        this.onselectorchange();

      if (inithandlers_cb)
        inithandlers_cb();

      const stockinfo = pricecalculation.constructStockInfoFromStockTiers(res.stocktiers);
      pricecalculation.publicizeStockInfo(this.webshop, this.node, stockinfo, res.stocktiers);

      this._updatePrice(sethash);
    } finally {
      this._updating_selects = false;
      lock.release();
    }
  }

  _getAmount({ fixillegal = true } = {}) {
    if (this.productinfo.fixedamount)
      return this.productinfo.fixedamount;

    const amountcontrol = this.node.querySelector<HTMLInputElement | HTMLSelectElement>("[name=amount]");
    if (!amountcontrol) {
      //sometimes 'order amounts' don't make sense in a shop, so assume 1 if the control is missing
      return 1;
    }

    let amount = parseInt(amountcontrol.value);
    if (!(amount > 0)) { //NaN-safe compare
      if (fixillegal)
        amount = 1;
      else
        this.webshop.reportStatus(`An invalid order amount was specified (${amountcontrol.value})`);
    }

    return amount;
  }

  getSelectedOptions() {
    const currentoptions = [];

    if (this.productinfo.fixedoptions) {
      for (const opt of this.productinfo.fixedoptions) {
        const optionid = parseInt(opt.productvalue);
        if (!optionid)
          continue; //apparently a nonrequired option, which gets passed as 0. don't add it!

        currentoptions.push({
          optionid,
          label: opt.label,
          selected: opt.value,
          fixed: true
        });
      }
    }

    for (const optionrow of dompack.qSA(this.node, ".webshop-product__option")) {
      const optionlabel = dompack.qR(optionrow, ".webshop-product__optionlabel");
      const select = dompack.qR<HTMLSelectElement>(optionrow, ".webshop-product__optionselect");

      const selected = select.options[select.selectedIndex];
      if (selected) {
        if (!selected.value)
          continue;

        currentoptions.push({
          optionid: parseInt(selected.value),
          label: optionlabel.textContent,
          selected: selected.textContent,
          hiddendefault: optionrow.classList.contains("webshop-product__option--hiddendefault"),
          fixed: false
        });
      }
    }

    return currentoptions;
  }

  _gatherCurrentOptions() {
    const sourceoptions = this.getSelectedOptions();
    const fixedoptions = sourceoptions.filter(e => e.fixed).map(e => e.optionid);
    const options = sourceoptions.map(e => e.optionid);
    return { fixedoptions, options };
  }

  _cartUpdated() {
    this._updateAfterSelectionChange({ forcartupdate: true });
  }

  async _updatePrice(sethash?: boolean) {
    if (!this._initialized) //we only await if needed, helps tests who update options/amounts to not have to await themselves
      await this._initializedpromise;

    const amount = this._getAmount();

    const { options } = this._gatherCurrentOptions();
    const { hash } = pricecalculation.updateProductPrices(this.webshop, this.node, { amount, options, oncalculateprice: this.oncalculateprice });
    if (sethash) {
      if (hash.toString())
        location.hash = "#" + hash;
      else if (location.hash)
        location.hash = '#';
    }
  }

  async _doAdd(event: MouseEvent) {
    await this._initializedpromise;

    event.preventDefault();
    event.stopPropagation();

    const amount = this._getAmount({ fixillegal: false });
    if (!(amount >= 1))
      return;

    if (this.oncheckbeforeadd && !this.oncheckbeforeadd())
      return;

    let lock: dompack.Lock | null = dompack.flagUIBusy();

    let configuration;
    if (this.oncalculateconfiguration)
      configuration = this.oncalculateconfiguration();

    const fulloptions = this.getSelectedOptions();
    const options = fulloptions.map(e => e.optionid);
    const priceinfo = pricecalculation.calculateProductPrices(this.webshop, this.node, { amount, options, oncalculateprice: this.oncalculateprice });
    const discounts = pricecalculation.getRelevantDiscountsForProduct(this.webshop, this.productinfo.id);
    const bestthumbnail = pricecalculation.getBestImage(this.productinfo.thumbnails, options);

    const addedproduct = {
      product: this.productinfo.id,
      amount,
      options: fulloptions,
      baseprice: priceinfo.prediscountprice,
      priceonrequest: priceinfo.priceonrequest,
      discount: priceinfo.price === "onrequest" ? "onrequest" : finmath.subtract(priceinfo.prediscountprice, priceinfo.price),
      title: this.productinfo.title,
      thumbnail: bestthumbnail,
      discounts,
      configuration,
      brand: this.productinfo.brand,
      categorypath: this.productinfo.categorypath,
      sku: this.productinfo.sku,
      triedaddamount: amount,
      totalamount: 0
    };

    //add to cart (ADDME send a RPC to confirm/update)
    const { lineuid, addedamount, totalamount } = await this.webshop._addToCart(addedproduct, { mergeidenticalconfigs: this.productinfo.cartmergeidenticalconfigs });
    addedproduct.amount = addedamount;
    addedproduct.totalamount = totalamount;

    //reset the ordered amount if present
    const amountcontrol = this.node.querySelector<HTMLInputElement | HTMLSelectElement>("[name=amount]");
    if (amountcontrol)
      amountcontrol.value = "1";

    const hascrosssell = Boolean(this.node.dataset.webshopHascrosssell);

    try {
      if (addedproduct.amount === 0) {
        this.webshop.reportStatus(getTid("webshop:frontend.checkout.nomoreselectedproductinstock"));
        return;
      }

      if (addedproduct.amount !== addedproduct.triedaddamount) {
        lock.release();
        await this.webshop.reportStatus(getTid("webshop:frontend.checkout.selectedproductpartialamountinstock", addedproduct.amount));
        lock = dompack.flagUIBusy();
      }

      if (!dompack.dispatchCustomEvent(event.target!, "webshop:productadded", { bubbles: true, cancelable: true, detail: { ...addedproduct, webshop: this.webshop, productnode: this.node, lineuid, hascrosssell } }))
        return; //default action is cancelled

      window.setTimeout(() => {
        if (hascrosssell)
          // FIXME: make this URL builder a webshop function
          navigateTo(this.webshop.options.catalogroot + `webshop/combine?p=${this.productinfo.id}&o=${options.join(",")}&i=${lineuid}&a=${addedamount}`);
        else
          this.webshop.gotoCheckout();
      }, 100); //give GTM time to pick up the product add  (TODO fix this in the main code. work with GTA to get supporting browsers into BEACON mode)
      lock = null; // keep the lock until the redirect

    } finally {
      if (lock)
        lock.release();
    }
  }

  async _onSelectorChange() {
    if (!this._initialized) //we only await if needed, helps tests who update options/amounts to not have to await themselves
      await this._initializedpromise;

    if (this._updating_selects)
      return;

    await this._updateAfterSelectionChange({ sethash: true });

    if (this.onselectorchange)
      this.onselectorchange();
  }

  updatePrice() {
    if (!this._updating_selects)
      this._updateAfterSelectionChange();
    else
      this._updatePrice(true);
  }
}

export default Product;
