//@ts-nocheck -- converted from ES
import * as dompack from 'dompack';
import * as formrpc from "@mod-publisher/js/forms/rpc";
import * as whintegration from '@mod-system/js/wh/integration';

let config: {
  title: string;
  structured: unknown;
} | undefined;


async function submitContactRequest(evt: HTMLEvent) {
  dompack.stop(evt);

  const form = evt.target;
  const producttitle = config?.title ?? document.querySelector(".webshop-product__title")?.textContent ?? document.title;

  const fields = { firstname2:    form.elements.firstName2.value,
                lastname2:     form.elements.lastName2.value,
                company2:      form.elements.company2.value,
                email2:        form.elements.email2.value,
                phonenumber2:  form.elements.phoneNumber2.value,
                comments2:     form.elements.comments2.value,
                producturl2:   window.location.href,
                product2:      producttitle,
                structured:   config?.structured ?? "null"};

  const result = await formrpc.submitForm(whintegration.config.site.contactformtarget, fields, config?.structured ? { extrasubmit: JSON.parse(config.structured) } : {});

  const redirecturl = document.getElementById('redirecturl2').value;

  if(result.success)
    // eslint-disable-next-line require-atomic-updates  -- should navigateTo but that might not work until WH5.3 ?
    window.location = redirecturl;
  else
    // eslint-disable-next-line no-alert
    alert("Resultaat: " + JSON.stringify(result));
}

dompack.register('#contactForm', node => node.addEventListener("submit", evt => submitContactRequest(evt)));
