import "./categorypage.scss";
import "./productpage.scss";
import * as dompack from 'dompack';
import * as domevents from "dompack/src/events";
import * as merge from 'dompack/extra/merge';
import * as finmath from '@mod-system/js/util/finmath';

import $ from "jquery";

merge.registerUpdater("attentieProductPagePreviewImage", (node, data) =>
{
  node.dataset.initialidx = data.idx;
  if (node.classList.contains("slick-initialized"))
    $(node).slick("slickGoTo", data.idx);
});


dompack.onDomReady(() =>
{
  let productimages = document.querySelectorAll("#product__images__grid__container .product__image").length;

  if( !productimages )
  return;

  if(productimages <= 1) {
    document.querySelector("#product__images__grid__container").classList.add("d-none");
  }
});
$( document ).ready(function()
{
    //active state tab productpage
    $( '.product__info ul.nav a' ).on( 'click', function ()
    {
      $( '.product__info ul.nav' ).find( 'li.active' ).removeClass( 'active' );
      $( this ).parent( 'li' ).addClass( 'active' );
    });

    // init magnific popup in productpage
    var form = document.getElementById("product__images__preview__container");
    var have_images = document.querySelectorAll('.product__images__grid, .product__images__preview').length !== 0;

    if (typeof (form) != 'undefined' && form !== null && have_images)
    {
        $('.product__images__grid, .product__images__preview').magnificPopup(
        {
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery:
            {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image:
            {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: item => item.el.find('img').attr('alt')
            }
        });

      const initialSlide = document.querySelector(".product__images__grid").dataset.initialidx | 0;

      $('#product__images__preview__container').slick(
      {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        infinite: false,
        fade: false,
        asNavFor: '.product__images__grid',
        initialSlide
      });

      $('.product__images__grid').slick(
      {
        lazyLoad: 'ondemand',
        slidesToShow: 4,
        infinite: false,
        slidesToScroll: 1,
        asNavFor: '#product__images__preview__container',
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        initialSlide,
        responsive: [
            {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4
            }
          },
            {
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      });
    }
});


let target = dompack.qS('#btn-filter');

if (typeof (target) != 'undefined' && target !== null)
{
  target.onclick = function (e)
  {
    let elem = dompack.qS('#productsgrid_anchor');
    elem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
    e.preventDefault();
  }
}

(function () {
  'use strict'

  var forms = document.querySelectorAll('.modalform form')

  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})()

// Verwerkt imprint informatie
addEventListener("forshops:promidata-priceinfo", event =>
{
  let inject_into_element = dompack.qS("#promidata-price-info"); //TODO update deze selector

  console.log("priceupdate", inject_into_element, event.detail);

  let { rawprices, rawimprints, baseprices, imprints, quantities, sizes, countallsizes } = event.detail;

  // let minimum_order_quantity = dompack.qSA(".minquantity");
  // let minimum_order_quantity_input = dompack.qS("#amount");
  // let minimum_quantity = rawprices[0].prices.filter(_ => _.quantity != "1").map(_ => _.quantity)[0]; // get first quantity and skip if quantity is 1

  // if (minimum_order_quantity_input)
  // {
  //   minimum_order_quantity_input.value = minimum_quantity
  //   minimum_order_quantity_input.min = minimum_quantity
  // }

  // let div_array = [...minimum_order_quantity];
  // div_array.forEach(div =>
  // {
  //   div.innerHTML = minimum_quantity
  // });

  if(!rawprices[0].prices.length)
   return;

  const pricerows = countallsizes ? baseprices.slice(0,1) : baseprices;

  inject_into_element.replaceChildren(
   <div id="priceinfo-noprint">
     <table class="table table-striped table-sm">
       <thead>
         <tr>
           { sizes.length && !countallsizes ? <th scope="column">Maat</th> : null}
           { quantities.map( quantity => <th scope="column">{quantity} st.</th> )}
         </tr>
       </thead>
       <tbody>
         { pricerows.map(row =>
           <tr>
             { sizes.length && !countallsizes  ? <th scope="row">{row.size}</th> : null }
             { row.perpiece.map( perpiece =>
                 <td> {finmath.formatPrice(perpiece,'.',2)}</td>
               )}
           </tr>)
         }
       </tbody>
     </table>

     { imprints.map(position =>
         <div class="d-none">
           <p>- {position.title}</p>
           <table>
             <thead>
               <tr>
                 <th></th>
                 { quantities.map( quantity => <th scope="column">{quantity} st.</th> )}
               </tr>
             </thead>
             { position.options.map(row =>
               <tr>
                 <th scope="row">{row.title}</th>
                 { row.perpiece.map( perpiece =>
                     <td>{finmath.formatPrice(perpiece,'.',2)} st.</td>
                   )}
               </tr>)
             }
           </table>
           <hr/>
         </div>
         )
     }
   </div>
   );
});

function convertPromidataVideoUrl()
{
  const videoUrl = dompack.qS('.promidata_videourl');
  const videoBtn = dompack.qS('.promidata_videobtn');
  const videoModal = dompack.qS('#videoModal');

  if (!videoUrl || !videoBtn || !videoModal)
    return;

  let videoId;

  if (videoUrl.src.includes('vimeo.com/'))
  {
    videoId = videoUrl.src.split("vimeo.com/")[1];
    videoUrl.src = 'https://player.vimeo.com/video/' + videoId + '?dnt=1';
    videoModal.addEventListener('show.bs.modal', event => {
      videoUrl.src += '&autoplay=1';
    });
    videoModal.addEventListener('hidden.bs.modal', event => {
      videoUrl.src = 'https://player.vimeo.com/video/' + videoId + '?dnt=1';
    });
  }
  else if (videoUrl.src.includes('youtube.com/'))
  {
    videoId = videoUrl.src.split("/watch?v=")[1];
    videoUrl.src = 'https://www.youtube-nocookie.com/embed/' + videoId;
    videoModal.addEventListener('show.bs.modal', event => {
      videoUrl.src += '?autoplay=1';
    });
    videoModal.addEventListener('hidden.bs.modal', event => {
      videoUrl.src = 'https://www.youtube-nocookie.com/embed/' + videoId;
    });
  }
  else if (videoUrl.src.includes('youtu.be/'))
  {
    videoId = videoUrl.src.split('youtu.be/')[1];
    videoUrl.src = 'https://www.youtube-nocookie.com/embed/' + videoId;
    videoModal.addEventListener('show.bs.modal', event => {
      videoUrl.src += '?autoplay=1';
    });
    videoModal.addEventListener('hidden.bs.modal', event => {
      videoUrl.src = 'https://www.youtube-nocookie.com/embed/' + videoId;
    });
  }
}

dompack.onDomReady(convertPromidataVideoUrl);
